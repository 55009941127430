import { getApiUrl } from '~/api/utils';

/**
 * Submits a name guess for a specific riddle.
 *
 * @param subscription
 *
 * @throws Error on API request failure.
 */
export const addSubscription = async (subscription: PushSubscription) => {
  const apiUrl = getApiUrl();
  const response = await fetch(`${apiUrl}/webpush/subscription `, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subscription)
  });
  if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  return response.json();
};
